import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IAnnouncement} from '../../shared/model/announcement.model';
import {AnnouncementRepository} from '../repository/announcement.repository';


@Injectable({providedIn: 'root'})
export class AnnouncementService {
  constructor(private announcementRepo: AnnouncementRepository) {}

  public getAnnouncement(id: string): Observable<IAnnouncement> {
    return this.announcementRepo.getAnnouncement(id);
  }

  public addAnnouncement(announcement: Partial<IAnnouncement>): Observable<IAnnouncement> {
    return this.announcementRepo.addAnnouncement(announcement);
  }

  public updateAnnouncement(announcement: IAnnouncement): Observable<IAnnouncement> {
    return this.announcementRepo.updateAnnouncement(announcement);
  }

  public getOpportunityAnnouncements(opportunityId: string): Observable<IAnnouncement[]> {
    return this.announcementRepo.getOpportunityAnnouncements(opportunityId);
  }

  public getAdminAnnouncements(): Observable<IAnnouncement[]> {
    return this.announcementRepo.getAdminAnnouncements();
  }
}
